import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

class Head extends React.Component
{
    renderRobotsTag()
    {
        const { index = true, follow = true } = this.props;
        const indexValue = index ? 'INDEX' : 'NOINDEX';
        const followValue = follow ? 'FOLLOW' : 'NOFOLLOW';

        return (
            <meta name="robots" content={indexValue + ', ' + followValue}/>
        );
    }

    render()
    {
        const {
            ogImage,
            ogTitle,
            ogDescription,
            ogUrl,
        } = this.props;

        return (
            <Helmet>
                <html lang={this.props.locale || 'de'} amp={true} />
                {this.renderRobotsTag()}
                <meta property="og:image" content={ogImage} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:title" content={ogTitle} />
                <meta property="og:url" content = {ogUrl} />
                <title>{ogTitle}</title>
            </Helmet>
        );
    }
}

Head.propTypes = {
    ogImage: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogUrl: PropTypes.string,
    index: PropTypes.bool,
    follow: PropTypes.bool,
    locale: PropTypes.string,
};

export default Head;
