import ReactGA from 'react-ga';
import Module from '../../../application/Service/Module';
import parameters from '../../../application/_config/parameters';

const { analyticsId } = parameters;
// Initialise analytics

function activateAnalytics()
{
    ReactGA.initialize(analyticsId);
    ReactGA.ga('set', 'anonymizeIp', true);

    return {
        activated: true,
    };
}

if (typeof window !== 'undefined')
{
    activateAnalytics();
}

export const reportPageViewToAnalytics = () =>
{
    if (analyticsId)
    {
        ReactGA.pageview(Module.get().location.pathname + Module.get().location.search);
    }
};

export const reportEventToAnalytics = ({ category, action }) =>
{
    if (!analyticsId)
    {
        return;
    }
    if (!action)
    {
        throw new Error('No Action provided to analytics event report');
    }

    if (!category)
    {
        throw new Error('No Category provided to analytics event report');
    }

    ReactGA.event({
        category,
        action,
    });
};

export const optOutGoogleAnalytics = () =>
{
    if (analyticsId && Module.get().ga && !Boolean(Module.get()['ga-disable-' + analyticsId]))
    {
        Module.get()['ga-disable-' + analyticsId] = true;
        // eslint-disable-next-line no-console
        console.log('Google Analytics wurde deaktiviert.');
    }
    else
    {
    // eslint-disable-next-line no-console
        console.log('Google Analytics konnte nicht deaktiviert werden, weil es nicht aktiviert ist.');
    }
};

